































































import Vue from "vue";
import MainSection from "@/components/MainSection.vue";
import { NavbarCategories } from "@/components/layout";
import { TruckList } from "@/components/TruckList";
import InfiniteLoading from "vue-infinite-loading";

import { mapMutations, mapState } from "vuex";
import { pagination } from "@/mixins";
import { MUT_TRUCKS } from "@/store/mutations/types";

export default Vue.extend({
  name: "trucks-by-brand",
  mixins: [pagination],
  components: {
    MainSection,
    NavbarCategories,
    TruckList,
    InfiniteLoading,
  },
  data() {
    return {
      brandId: "",
    };
  },
  computed: {
    ...mapState(["brands", "trucks"]),
    brandName(): string | boolean {
      let result = "";
      const { brand: brandId } = this.$route.params;

      if (this.brands.length == 0) return false;

      const brand = this.brands.find((brandItem: { _id: string }) => {
        return brandItem._id == brandId;
      });

      result = brand.name;
      return result;
    },
    url(): string {
      const url = `truck?brandId=${this.brandId}&${
        (this as any).paginationParams
      }`;

      return url;
    },
  },
  methods: {
    ...mapMutations([MUT_TRUCKS]),
    infiniteHandler($state: any) {
      this.brandId = this.$route.params.brand;
      (this as any).getTrucks($state, this.url);
    },
  },
  beforeRouteUpdate(to, from, next) {
    const infiniteLoading = this.$refs.infiniteLoading;

    // Limpiar y reiniciar los estados cuando se cambia de marca (brand)
    this[MUT_TRUCKS]([]);
    this.brandId = to.params.brand;
    (this as any).resetParams();
    (this as any).loading = true;
    (this as any)
      .getTrucks((infiniteLoading as any).stateChanger, this.url)
      .then(() => {
        (this as any).loading = false;
      });

    next();
  },
});
